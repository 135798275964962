import { css } from '@emotion/css';

import TailwindTheme from '@/../tailwind/theme';
import {
  ButtonColor,
  ButtonColorEAIR,
  ButtonColorEDS,
  ButtonColorELS,
  ButtonColorESN,
  ButtonColorGame,
  ButtonSize,
  PageTheme,
} from '@/types/views/generic';

import { NormalButtonProps } from './normal';

const { colors } = TailwindTheme;

export const buttonSizes: Record<ButtonSize, string> = {
  S: 'px-8 py-2 rounded-8',
  M: 'px-6 py-2 rounded-2',
  L: 'px-12 py-3 rounded-2',
  XL: 'px-16 py-4 rounded-2',
};

export const buttonDefault: Record<ButtonColor, string> = {
  red: 'text-white bg-red-medium hover:bg-red disabled:bg-gray-2',
  white: 'text-black bg-white hover:bg-red-light-darker disabled:bg-gray-3',
  gray: 'text-black bg-white hover:bg-red-light-darker disabled:bg-gray-3',
  black: 'text-white bg-black',
};

export const buttonGhost: Record<ButtonColor, string> = {
  red: 'text-red-medium disabled:text-gray-2 border-red-medium disabled:border-gray-2 bg-transparent hover:text-red border-2 hover:border-red',
  white:
    'text-white border-white bg-transparent border-2 hover:bg-white hover:bg-opacity-10',
  gray: 'text-gray-2 border-gray-2 bg-transparent border-2 hover:bg-white hover:bg-opacity-10',
  black: 'text-white border-2 border-black',
};

export const buttonEAIR: Record<ButtonColorEAIR, string> = {
  white:
    'text-air-deepBlue bg-white hover:bg-air-lila-light disabled:bg-gray-3',
  blue: 'text-white bg-air-deepBlue hover:bg-air-lila disabled:bg-gray-3',
  coral: 'text-white bg-air-coral hover:bg-air-coral-light disabled:bg-gray-2',
};

export const buttonESN: Record<ButtonColorESN, string> = {
  mint: 'text-black bg-esn-primary hover:bg-esn-primary-light disabled:bg-gray-3',
  blue: 'text-white bg-esn-secondary hover:bg-esn-secondary-light disabled:bg-gray-3',
};

export const buttonEDS: Record<ButtonColorEDS, string> = {
  pink: 'text-eds-black bg-eds-pink hover:bg-eds-pink-light disabled:bg-gray-3',
  black: 'text-white bg-eds-black hover:bg-eds-black-light disabled:bg-gray-3',
};

export const buttonELS: Record<ButtonColorELS, string> = {
  blue: 'text-white bg-els-blue hover:bg-els-blue-light disabled:bg-gray-3',
  'light-blue':
    'text-white bg-els-blue-light hover:bg-els-blue disabled:bg-gray-3',
  'grey-blue':
    'text-els-white-warm bg-els-blue-grey hover:bg-els-blue disabled:bg-gray-3',
};

export const buttonGame: Record<ButtonColorGame, string> = {
  black:
    'text-white border-2 bg-black border-black disabled:bg-gray-3 disabled:border-gray-3',
  white:
    'text-black border-2 bg-white border-white disabled:bg-gray-3 disabled:border-gray-3',
};

export const buttonVariants = (
  variant: NormalButtonProps['variant'],
  color: NormalButtonProps['color'],
) => {
  const variants = {
    default: buttonDefault[color as ButtonColor],
    ghost: buttonGhost[color as ButtonColor],
    eair: buttonEAIR[color as ButtonColorEAIR],
    esn: buttonESN[color as ButtonColorESN],
    eds: buttonEDS[color as ButtonColorEDS],
    els: buttonELS[color as ButtonColorELS],
    game: buttonGame[color as ButtonColorGame],
  };

  return variant && color ? variants[variant] ?? '' : '';
};

export const textLink = {
  wrapper:
    'text-body2 font-semibold group text-red-medium hover:text-red cursor-pointer inline-flex items-center',
  title: 'relative underline text-left',
  iconWrapper: 'relative ml-2 inline-block',
  circledIconArrow: (theme: PageTheme) =>
    theme === 'light' ? `fill-white` : `fill-black`,
  circledIconBackground: (theme: PageTheme) =>
    theme === 'light' ? `fill-black-light` : `fill-white`,
};

const wrapperStyles = css`
  @media (min-width: 375px) {
    flex-direction: row;
  }
`;

const rightSideStyles = css`
  @media (min-width: 375px) {
    margin-left: 0.5em;
    margin-top: 0;
  }
`;

export const textBorder = {
  DEFAULT: css`
    text-shadow: 0 1px ${colors.red.medium}, 0 -1px ${colors.red.medium},
      1px 0 ${colors.red.medium}, -1px 0 ${colors.red.medium};

    &:hover {
      text-shadow: 1px 1px ${colors.red.DEFAULT}, 1px -1px ${colors.red.DEFAULT},
        -1px -1px ${colors.red.DEFAULT}, -1px 1px ${colors.red.DEFAULT},
        1px 0 ${colors.red.DEFAULT}, -1px 0 ${colors.red.DEFAULT};
    }
  `,
  esn: css`
    text-shadow: 0 1px ${colors.esn.primary.DEFAULT},
      0 -1px ${colors.esn.primary.DEFAULT}, 1px 0 ${colors.esn.primary.DEFAULT},
      -1px 0 ${colors.esn.primary.DEFAULT};

    &:hover {
      text-shadow: 1px 1px ${colors.esn.primary.DEFAULT},
        1px -1px ${colors.esn.primary.DEFAULT},
        -1px -1px ${colors.esn.primary.DEFAULT},
        -1px 1px ${colors.esn.primary.DEFAULT},
        1px 0 ${colors.esn.primary.DEFAULT},
        -1px 0 ${colors.esn.primary.DEFAULT};
    }
  `,
};

export const oversizeC2A = {
  wrapper: `group text-body1 hover:text-red cursor-pointer inline-flex flex-col ${wrapperStyles}`,
  title: `max-w-2xl text-h1 text-black group-hover:text-red tesn:group-hover:text-esn-primary inline-block uppercase break-words`,
  rightSide: `flex mt-2 items-center ${rightSideStyles}`,
  iconWrapper: 'inline-block text-black',
  icon: 'w-8 md:w-16',
  iconArrow:
    'fill-red-medium tesn:fill-esn-primary group-hover:fill-black tesn:group-hover:fill-black',
  iconCircle:
    'stroke-red-medium tesn:stroke-esn-primary group-hover:stroke-white tesn:group-hover:fill-esn-primary group-hover:fill-white',
};
